import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Auth, API } from 'aws-amplify'
import Swal from 'sweetalert2'

// import { getStorage } from '../../config/cognito'
import { getLang } from '../../redux/selectors/lang'
const code = localStorage.getItem('marketingCode')

function Callback() {
  const history = useHistory()
  const lang = useSelector(getLang)
  const language = lang.lang

  console.log(language, 'aksdfjal;skdfjakl;sdjfl;sadkjfads')

  useEffect(() => {
    const swFire = async (options) => {
      history.push('/')
      await Swal.fire({
        ...options,
        confirmButtonColor: '#B6964E',
        // confirmButtonText: 'แอดไลน์ใหม่ ',
        confirmButtonText: 'แอดไลน์',

        showCloseButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          window.open('hhttps://line.me/R/ti/p/@817ghsgb')
        }
      })
    }

    // const storage = getStorage()

    Auth.currentAuthenticatedUser().then((user) => {
      if (user) {
        swFire({
          // title: 'สวัสดีครับ!',
          html: '',
          imageUrl: require(`../../assets/dong-banner/mobile/001.jpg`),
          // imageUrl: `https://${storage}.s3-ap-southeast-1.amazonaws.com/valentine-iphone.jpg`,
        })
        if (code) {
          console.log(typeof code)
          API.post('affiliate', '/ref/activate', {
            body: {
              username: user.username,
              refCode: code,
            },
          }).then((res) => {
            console.log(res, 'Affiliate')
          })
          API.post('marketing', '/ref/activate', {
            body: {
              username: user.username,
              refCode: code,
            },
          }).then((res) => {
            console.log(res, 'Marketing')
            localStorage.removeItem('marketingCode')
          })

        }
      }

      //   .then(() => {
      //     swFire({
      //       icon: 'success',
      //       title: 'ขอต้อนรับ!',
      //       text: `คุณได้เข้าสู่ระบบด้วยโค้ดของเพื่อน: ${code}`,
      //     })
      //   })
      //   .catch((err) => {
      //     const code = _get(err, 'response.data.code')
      //
      //     switch (code) {
      //       case 100: {
      //         swFire({
      //           icon: 'error',
      //           title: 'เกิดข้อผิดพลาด',
      //           text: 'โค้ดชวนเพื่อนไม่ถูกต้อง',
      //         })
      //         break
      //       }
      //       case 101: {
      //         swFire({
      //           icon: 'error',
      //           title: 'เกิดข้อผิดพลาด',
      //           text: 'คุณได้ทำการสมัครเข้าใช้งานหรือใช้โค้ดนี้ไปแล้ว',
      //         })
      //         break
      //       }
      //       default: {
      //         // internal error
      //         swFire({
      //           icon: 'error',
      //           title: 'เกิดข้อผิดพลาด',
      //           text: 'กรุณาติดต่อเจ้าหน้าที่',
      //         })
      //       }
      //     }
      //   })
    })
  }, [])

  return (
    <section className='inviteFriend inviteFriend__container'>
      <div className='inviteFriend__box inviteFriend__form' id='referral-details'>
        <form className='form__wrapper'>
          <div className='form__setWrapper'>
            <label htmlFor='invite-link' className='text-2xl text-center block text-white mb-6'>
              Login...
            </label>
          </div>
        </form>
      </div>
    </section>
  )
}

export default Callback
